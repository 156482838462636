<template>
  <!--min-height="245"-->
  <v-card>
    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          md="9"
        >
          {{ $t('lbl.room') }} {{ i }}
        </v-col>
        <!--v-if="max_travels > calculateCantTravels"-->
        <v-col
          cols="12"
          md="1"
        >
          <v-tooltip
            top
            color="primary"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                color="primary"
                :disabled="cotizadorHotels.ocupation.length === 6"
                v-on="on"
                @click="addOcupationRoomHotelCotizador()"
              >
                <v-icon>{{ icons.mdiPlus }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('btn.insert') }}</span>
          </v-tooltip>
        </v-col>
        <v-col
          v-if="cotizadorHotels.ocupation.length > 1"
          cols="12"
          md="1"
        >
          <v-tooltip
            top
            color="error"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                v-bind="attrs"
                color="error"
                v-on="on"
                @click="deleteOcupationRoomHotelCotizador(pos)"
              >
                <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
              </v-btn>
            </template>
            <span>{{ $t('btn.delete') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <v-row
        class="mt-0 pt-0"
        align="center"
        justify="center"
      >
        <!--ADULTS-->
        <v-col
          class="mb-0 pb-0"
          cols="6"
          lg="6"
          md="6"
        >
          <span class="font-weight-bold">{{ $t('booking.adultos') }}</span>
        </v-col>
        <v-col
          class="mb-0 pb-0"
          cols="6"
          lg="6"
          md="6"
        >
          <v-btn
            :disabled="min_adult == room.adults"
            class="mr-2 ml-2"
            outlined
            x-small
            fab
            color="#007BC3"
            @click="resAdult"
          >
            <v-icon>mdi-minus</v-icon>
          </v-btn>
          <label>{{ room.adults }}</label>
          <v-btn
            :disabled="
              max_adult == room.adults ||
                max_ocupation == room.adults + room.childs ||
                max_travels == calculateCantTravels
            "
            class="mr-2 ml-2"
            outlined
            x-small
            fab
            color="#007BC3"
            @click="sumAdult"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>

        <!--CHILDS-->
        <v-col
          class="mt-0 pt-0"
          cols="6"
          lg="6"
          md="6"
        >
          <span class="font-weight-bold">{{ $t('lbl.young') }}</span><br />
          <small class="gothBtMine tituloOcupacion">
            <!--{{ edad_infants }}-{{ edad_child }} -->
            0-{{ maxAge }}
            {{ $t('lbl.years') }}</small>
        </v-col>
        <v-col
          class="mt-0 pt-0"
          cols="6"
          lg="6"
          md="6"
        >
          <v-btn
            :disabled="min_child == room.childs"
            class="mr-2 ml-2"
            outlined
            x-small
            fab
            color="#007BC3"
            @click="resChild"
          >
            <v-icon>mdi-minus</v-icon>
          </v-btn>
          <label>{{ room.childs }}</label>
          <v-btn
            :disabled="
              max_child == room.childs ||
                max_ocupation == room.adults + room.childs ||
                max_travels == calculateCantTravels
            "
            class="mr-2 ml-2"
            outlined
            x-small
            fab
            color="#007BC3"
            @click="sumChild"
          >
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </v-col>
        <v-col
          class="mt-0 pt-0"
          cols="12"
          lg="12"
          md="12"
        >
          <v-row>
            <v-col
              v-if="room.childs > 0"
              class="mb-0 pb-0"
              cols="12"
              lg="12"
              md="12"
            >
              <span>{{ $t('lbl.edadChild') }}</span>
            </v-col>
            <v-col
              v-for="(edad, j) in room.edades"
              :key="j"
              cols="12"
              lg="4"
              md="4"
              class="pr-2 pt-0 mt-0"
            >
              <EdadOcupation
                :edad="edad"
                :edades="edades"
                :i-hab="pos"
                :i-edad="j"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!--<v-form class="mb-1 mt-2">
        <v-divider></v-divider>
      </v-form>-->
    </v-card-text>
  </v-card>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
  mdiDeleteOutline,
} from '@mdi/js'

import EdadOcupation from './EdadOcupation.vue'

export default {
  components: {
    EdadOcupation,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    i: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    pos: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    room: {
      type: Object,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    min_adult: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    max_adult: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    max_ocupation: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    max_travels: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    edad_infants: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    edad_child: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    min_child: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    max_child: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop, vue/prop-name-casing
    maxAge: {
      type: Number,
    },
  },
  data() {
    return {
      edades: [],
      cant_adult: 0,
      cant_child: 0,
      arr_edades: [],

      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
        mdiDeleteOutline,
      },
    }
  },
  computed: {
    ...mapState({
      cotizadorHotels: state => state.app.cotizadorHotels,
      showOcupationRoomHotel: state => state.app.showOcupationRoomHotel,
    }),
    calculateCantTravels() {
      let cantTravels = 0
      this.cotizadorHotels.ocupation.forEach(element => {
        cantTravels += element.adults + element.childs
      })

      return cantTravels
    },
  },
  created() {
    // eslint-disable-next-line no-plusplus
    for (let index = 0; index <= this.maxAge; index++) {
      this.edades.push(index)
    }
  },
  methods: {
    ...mapMutations([
      'setShowRoomHotelCotizador',
      'addOcupationRoomHotelCotizador',
      'deleteOcupationRoomHotelCotizador',
    ]),
    resAdult() {
      if (this.room.adults > this.min_adult) {
        this.room.adults -= 1
      }
    },
    sumAdult() {
      if (this.room.adults < this.max_adult) {
        this.room.adults += 1
      }
    },
    resChild() {
      if (this.room.childs > this.min_child) {
        this.room.childs -= 1
        this.room.edades.splice(this.room.edades.length - 1, 1)
      }
    },
    sumChild() {
      if (this.room.childs < this.max_child) {
        this.room.childs += 1
        this.room.edades.push({ edad: null })
      }
    },
  },
}
</script>
