<template>
  <v-form class="multi-col-validation mt-1">
    <v-container
      v-if="!loading"
      grid-list-xs
    >
      <v-row class="mb-3">
        <v-col
          cols="12"
          md="12"
          class="py-0"
        >
          <v-radio-group
            v-model="radioGroup"
            hide-details
            row
            dense
            light
            class="mt-0"
          >
            <v-radio
              :label="$t('booking.ida_vuelta')"
              @click="idaBack"
            ></v-radio>
            <v-radio
              :label="$t('booking.ida')"
              @click="onlyIda"
            ></v-radio>
            <v-radio
              :label="$t('booking.multiple_destino')"
              @click="multiplesDestinos"
            ></v-radio>
          </v-radio-group>
        </v-col>
      </v-row>
      <div>
        <FlightsDestinos
          v-for="(destino, index) in destinosCotizadorFlight"
          :key="Math.random() * (index + 1)"
          :index="index"
        />
        <v-row v-if="cotizadorFlight.destinosMultiples">
          <v-col
            cols="12"
            md="6"
          >
            <v-btn
              color="primary"
              width="100%"
              @click="addDestinoCotizadorFligth"
            >
              {{ $t('booking.addFligth') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row>
          <!--<v-col
            cols="12"
            md="2"
            class="text-center"
          >
            <v-text-field
              v-model="cotizadorFlight.cant_adults"
              :label="$t('booking.adultos')"
              outlined
              dense
              hide-details
              type="number"
              :prepend-inner-icon="icons.mdiMinus"
              :append-icon="icons.mdiPlus"
              @click:prepend-inner="decrementAdultosCotizadorFligth"
              @click:append="incrementAdultosCotizadorFligth"
            ></v-text-field>
          </v-col>-->
          <v-col
            cols="12"
            md="2"
            class="d-flex align-start justify-center"
          >
            <div
              id="weirdButMine"
              class="d-block justify-center"
            >
              <div class="d-flex justify-center align-center">
                <p
                  class="mb-0"
                  style="font-size:12px"
                >
                  {{ $t('booking.adultos') }}
                </p>
              </div>
              <div class="d-flex justify-center align-center">
                <v-btn
                  outlined
                  dense
                  class="boton-lefttMine d-flex justify-center align-end"
                  :disabled="
                    cotizadorFlight.cant_ninnos === 0
                      ? cotizadorFlight.cant_adults === 1
                      : cotizadorFlight.cant_adults === 0
                  "
                  @click="decrementAdultosCotizadorFligth"
                >
                  <v-icon color="primary">
                    {{ icons.mdiMinus }}
                  </v-icon>
                </v-btn>
                <v-text-field
                  v-model="cotizadorFlight.cant_adults"
                  hide-details
                  style="max-height: 20px;"
                  disabled
                ></v-text-field>
                <v-btn
                  outlined
                  dense
                  class="boton-rightMine d-flex justify-center align-end"
                  @click="incrementAdultosCotizadorFligth"
                >
                  <v-icon color="primary">
                    {{ icons.mdiPlus }}
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="2"
            class="d-flex align-start justify-center"
          >
            <div
              id="weirdButMine"
              class="d-block justify-center"
            >
              <div class="d-flex justify-center align-center">
                <p
                  class="mb-0"
                  style="font-size:12px"
                >
                  {{ $t('booking.childs') }} ({{ $t('booking.childsFlight') }})
                </p>
              </div>
              <div class="d-flex justify-center align-center">
                <v-btn
                  outlined
                  dense
                  class="boton-lefttMine d-flex justify-center align-end"
                  :disabled="
                    cotizadorFlight.cant_adults === 0
                      ? cotizadorFlight.cant_ninnos === 1
                      : cotizadorFlight.cant_ninnos === 0
                  "
                  @click="decrementChildCotizadorFligth"
                >
                  <v-icon color="primary">
                    {{ icons.mdiMinus }}
                  </v-icon>
                </v-btn>
                <v-text-field
                  v-model="cotizadorFlight.cant_ninnos"
                  hide-details
                  style="max-height: 20px;"
                  disabled
                  placeholder="1"
                ></v-text-field>
                <v-btn
                  outlined
                  dense
                  class="boton-rightMine d-flex justify-center align-end"
                  @click="incrementChildCotizadorFligth"
                >
                  <v-icon color="primary">
                    {{ icons.mdiPlus }}
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </v-col>
          <v-col
            cols="12"
            md="2"
            class="d-flex align-start justify-center"
          >
            <div
              id="weirdButMine"
              class="d-block justify-center"
            >
              <div class="d-flex justify-center align-center">
                <p
                  class="mb-0"
                  style="font-size:10px"
                >
                  {{ $t('booking.infants') }} ({{ $t('booking.infantsFlight') }})
                </p>
              </div>
              <div class="d-flex justify-center align-center">
                <v-btn
                  outlined
                  dense
                  class="boton-lefttMine d-flex justify-center align-end"
                  :disabled="cotizadorFlight.cant_infant === 0"
                  @click="decrementInfantCotizadorFligth"
                >
                  <v-icon color="primary">
                    {{ icons.mdiMinus }}
                  </v-icon>
                </v-btn>
                <v-text-field
                  v-model="cotizadorFlight.cant_infant"
                  hide-details
                  style="max-height: 20px;"
                  disabled
                ></v-text-field>
                <v-btn
                  outlined
                  dense
                  class="boton-rightMine d-flex justify-center align-end"
                  @click="incrementInfantCotizadorFligth"
                >
                  <v-icon color="primary">
                    {{ icons.mdiPlus }}
                  </v-icon>
                </v-btn>
              </div>
            </div>
          </v-col>
          <!-- <v-col cols="12" md="3">
            <v-select v-model="claseVuelo" :items="clase" label="Clase" outlined dense hide-details>
            </v-select>
          </v-col> -->
          <v-col
            cols="12"
            md="3"
          >
            <v-select
              v-model="cotizadorFlight.aerolinea"
              :items="aerolineas"
              :label="$t('menu.aerolinea')"
              outlined
              dense
              hide-details
              item-text="name"
              item-value="id"
              @change="setAerolinea"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            md="3"
          >
            <v-row>
              <v-col
                cols="12"
                :md="
                  cotizadorFlight.cant_adults === 1 &&
                    cotizadorFlight.cant_ninnos === 0 &&
                    cotizadorFlight.cant_infant === 0 &&
                    cotizadorFlight.aerolinea === 0 &&
                    destinosCotizadorFlight.length === 1 &&
                    !destinosCotizadorFlight[0].origen &&
                    !destinosCotizadorFlight[0].destino &&
                    !destinosCotizadorFlight[0].from &&
                    !destinosCotizadorFlight[0].to
                    ? '12'
                    : '6'
                "
              >
                <v-btn
                  color="primary"
                  width="100%"
                  :loading="loadingSearch"
                  @click="disponibilidadFlight"
                >
                  {{ $t('btn.search') }}
                </v-btn>
              </v-col>
              <v-col
                v-if="
                  cotizadorFlight.cant_adults !== 1 ||
                    cotizadorFlight.cant_ninnos > 0 ||
                    cotizadorFlight.cant_infant > 0 ||
                    cotizadorFlight.aerolinea > 0 ||
                    destinosCotizadorFlight.length > 1 ||
                    destinosCotizadorFlight[0].origen ||
                    destinosCotizadorFlight[0].destino ||
                    destinosCotizadorFlight[0].from ||
                    destinosCotizadorFlight[0].to
                "
                cols="12"
                md="6"
              >
                <v-btn
                  color="error"
                  width="100%"
                  @click="clearForm"
                >
                  {{ $t('btn.clear') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </div>
    </v-container>

    <CajaIntermediaFlight v-else />

    <div ref="resultFlight">
      <DisponibilidadFlight v-if="!loading && searchingFlightCotizador" />
    </div>
    <!--<Paginando v-if="paginando" />-->
  </v-form>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
} from '@mdi/js'
import FlightsDestinos from '../utils/FlightsDestinos.vue'
import CajaIntermediaFlight from '../utils/CajaIntermediaFlight.vue'

// import Paginando from '../utils/Paginando.vue'
import DisponibilidadFlight from '../disponibilidad/flights/Index.vue'

export default {
  components: {
    FlightsDestinos,
    CajaIntermediaFlight,

    // Paginando,
    DisponibilidadFlight,
  },
  data() {
    return {
      tab: 0,
      isLoadingFlihgt: true,
      itemsOrigen: [],
      searchOrigen: null,
      itemsDestino: [],
      searchDestino: null,
      paginando: false,
      loading: false,
      loadingSearch: false,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      aerolineas: [],
      radioGroup: 0,
      mostrarida: true,
      mostrarmultiple: false,
      mostraridavuelta: false,
      per_page: 10,

      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
      },
      isDialogVisible: false,
      abilities: [],
      abilityList: [],
      checkPermiso: 1,
      isLoad: true,
      permisos: localStorage.getItem('permisos_auth'),
      itemDelete: {},
      isDialogVisibleDelete: false,
    }
  },
  computed: {
    ...mapState({
      cotizadorFlight: state => state.app.cotizadorFlight,
      destinosCotizadorFlight: state => state.app.destinosCotizadorFlight,
      searchingFlightCotizador: state => state.app.searchingFlightCotizador,
      searchCotizadorFlights: state => state.app.searchCotizadorFlights,
      pageSearchingFlightCotizador: state => state.app.pageSearchingFlightCotizador,
    }),
  },
  mounted() {
    this.scroll()
  },
  created() {
    this.getAerolineas()
    this.resetCotizadorFligth()
    this.emptyDestinoCotizadorFligth()
  },
  methods: {
    ...mapMutations([
      'addDestinoCotizadorFligth',
      'resetDestinoCotizadorFligth',
      'emptyDestinoCotizadorFligth',
      'resetCotizadorFligth',
      'incrementAdultosCotizadorFligth',
      'decrementAdultosCotizadorFligth',
      'incrementChildCotizadorFligth',
      'decrementChildCotizadorFligth',
      'incrementInfantCotizadorFligth',
      'decrementInfantCotizadorFligth',
      'setResultsCotizadorIdaFligth',
      'setResultsCotizadorRegresoFligth',
      'setAerolineCotizadorIdaFligth',
      'setAerolineCotizadorRegresoFligth',
      'setFiltrosCotizadorFlight',
      'setSearchingFlightCotizador',
      'setPageSearchingFlightCotizador',
      'setAerolineCotizadorDestinosFligth',
      'clearCotizadorFlightFavorite',
    ]),
    scroll() {
      window.onscroll = () => {
        const bottomOfWindow = document.documentElement.scrollTop + window.innerHeight === document.documentElement.offsetHeight
        if (bottomOfWindow) {
          if (this.destinosCotizadorFlight.length > 0) {
            const json = this.cotizadorFlight
            json.destinos = this.destinosCotizadorFlight

            let proceder = true
            if (!this.cotizadorFlight.onlyIda && !this.cotizadorFlight.destinosMultiples) {
              this.destinosCotizadorFlight.forEach(element => {
                if (
                  element.from === null
                  || element.to === null
                  || element.origen === null
                  || element.destino === null
                ) {
                  proceder = false
                }
              })
            } else {
              this.destinosCotizadorFlight.forEach(element => {
                if (element.from === null || element.origen === null || element.destino === null) {
                  proceder = false
                }
              })
            }
            if (proceder && !this.searchCotizadorFlights) {
              this.setPageSearchingFlightCotizador(this.pageSearchingFlightCotizador + 1)
              // eslint-disable-next-line radix
              json.per_page = parseInt(this.pageSearchingFlightCotizador) * parseInt(this.per_page)
              this.paginando = true
              this.axios
                .post('cotizador-search-flight', json, {
                  headers: {
                    Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
                  },
                })
                .then(res => {
                  this.setAerolineCotizadorIdaFligth(res.data.data.aerolineIda)
                  if (res.data.data.aerolineRegreso.length > this.aerolineCotizadorRegresoFlight.length) {
                    this.setAerolineCotizadorRegresoFligth(res.data.data.aerolineRegreso)
                  }
                  this.setAerolineCotizadorDestinosFligth(res.data.data.aerolineDestinos)
                })

                .finally(() => {
                  this.paginando = false
                })
            }
          }
        }
      }
    },
    getAerolineas() {
      this.axios
        .post('cotizador-airlines', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.aerolineas = res.data.data.data
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoadingFlihgt = false))
    },
    setAerolinea() {
      this.cotizadorFlight.aerolinea_name = this.aerolineas.find(
        item => item.id === this.cotizadorFlight.aerolinea,
      ).name
    },
    clearForm() {
      this.radioGroup = 0
      this.resetCotizadorFligth()
      this.emptyDestinoCotizadorFligth()

      this.setAerolineCotizadorIdaFligth([])
      this.setAerolineCotizadorRegresoFligth([])
      this.setAerolineCotizadorDestinosFligth([])
      this.setSearchingFlightCotizador(false)
    },
    disponibilidadFlight() {
      if (this.destinosCotizadorFlight.length > 0) {
        this.loadingSearch = true
        const json = this.cotizadorFlight
        json.destinos = this.destinosCotizadorFlight
        // eslint-disable-next-line radix
        json.per_page = parseInt(this.pageSearchingFlightCotizador) * parseInt(this.per_page)

        const filtros = {
          aerolinea: this.cotizadorFlight.aerolinea,
        }
        this.setFiltrosCotizadorFlight(filtros)

        // console.log(json)
        // localStorage.setItem('cotizadorFlight', JSON.stringify(json))
        // eslint-disable-next-line no-unused-vars
        let proceder = true
        if (!this.cotizadorFlight.onlyIda && !this.cotizadorFlight.destinosMultiples) {
          this.destinosCotizadorFlight.forEach(element => {
            if (element.from === null || element.to === null || element.origen === null || element.destino === null) {
              proceder = false
            }
          })
        } else {
          this.destinosCotizadorFlight.forEach(element => {
            if (element.from === null || element.origen === null || element.destino === null) {
              proceder = false
            }
          })
        }
        if (proceder) {
          // this.clearCotizadorFlightFavorite()
          // this.loading = true
          this.setSearchingFlightCotizador(false)
          this.axios
            .post('cotizador-search-flight', json, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(res => {
              // console.log(res)
              if (res.data.success) {
                this.setAerolineCotizadorIdaFligth(res.data.data.aerolineIda)
                this.setAerolineCotizadorRegresoFligth(res.data.data.aerolineRegreso)
                this.setAerolineCotizadorDestinosFligth(res.data.data.aerolineDestinos)
              } else {
                this.setAerolineCotizadorIdaFligth([])
                this.setAerolineCotizadorRegresoFligth([])
                this.setAerolineCotizadorDestinosFligth([])
              }
              this.setSearchingFlightCotizador(true)

              // this.$router.push({ name: 'disponibilidad-flights' })
            })
            // eslint-disable-next-line no-return-assign
            .finally(() => {
              this.loading = false
              this.loadingSearch = false

              // DAR ATRAZO A LA RESPUESTA PARA QUE SE MUESTRE EL SPINNER
              /* setTimeout(() => {
                this.$refs.resultFlight.scrollTo(0, 150)
              }, 1500) */
            })
        } else {
          this.loadingSearch = false
          this.$toast.error(this.$t('msg.datasRequiere'))
        }
      } else {
        this.loadingSearch = false
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    onlyIda() {
      this.cotizadorFlight.onlyIda = true
      this.cotizadorFlight.destinosMultiples = false
      this.resetDestinoCotizadorFligth()
      this.setSearchingFlightCotizador(false)
      this.setPageSearchingFlightCotizador(1)
      this.clearCotizadorFlightFavorite()
    },
    idaBack() {
      this.cotizadorFlight.onlyIda = false
      this.cotizadorFlight.destinosMultiples = false
      this.resetDestinoCotizadorFligth()
      this.setSearchingFlightCotizador(false)
      this.setPageSearchingFlightCotizador(1)
      this.clearCotizadorFlightFavorite()
    },
    multiplesDestinos() {
      this.cotizadorFlight.onlyIda = false
      this.cotizadorFlight.destinosMultiples = true
      this.addDestinoCotizadorFligth()
      this.setSearchingFlightCotizador(false)
      this.setPageSearchingFlightCotizador(1)
      this.clearCotizadorFlightFavorite()
    },
  },
}
</script>

<style>
.theme--light.v-text-field.v-input--is-disabled .v-input__slot:before {
  border: none;
}

.v-input__slot {
  margin-bottom: 0px !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div
  > div
  > div {
  padding: 0 !important;
}

#app
  > div.v-application--wrap
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(3)
  > div {
  padding: 0 !important;
  margin-top: 0 !important;
}

#app
  > div.v-application--wrap
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(4)
  > div {
  padding: 0 !important;
  margin-top: 0 !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div::before {
  box-shadow: none !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div {
  box-shadow: none !important;
  padding-bottom: 10px;
}
#app
  > div.v-application--wrap
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(2)
  > div.pl-0.col-md-3.col-12
  > div
  > div
  > div
  > div
  > label {
  padding-left: 10px !important;
  z-index: 999 !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div {
  box-shadow: none !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div {
  box-shadow: none !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div::before {
  box-shadow: none !important;
}
.boton-lefttMine,
.boton-rightMine {
  max-height: 20px !important;
  min-width: 40px !important;
  padding: 0px !important;
  border: none !important;
}

#weirdButMine {
  border: 1px solid #dcdade;
  max-height: 40px;
  border-radius: 5px;
}

#weirdButMine input {
  height: 20px;
  text-align: center;
}

#weirdButMine div:nth-child(2) > div {
  height: 20px;
  padding: 0;
  margin: 0;
}

#weirdButMine .v-input__slot:after {
  border: none !important;
}

#weirdButMine .v-input__slot:before {
  border: none;
}

#weirdButMine button::before {
  background-color: rgba(255, 255, 255, 0);
}

#weirdButMine button .v-ripple__container {
  color: rgba(255, 255, 255, 0);
  background-color: rgba(255, 255, 255, 0);
}

.vc-pane-layout {
  z-index: 99999;
}
</style>
