var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_vm._v(" "+_vm._s(_vm.$t('lbl.room'))+" "+_vm._s(_vm.i)+" ")]),_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"primary","disabled":_vm.cotizadorHotels.ocupation.length === 6},on:{"click":function($event){return _vm.addOcupationRoomHotelCotizador()}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))])],1)]}}])},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.insert')))])])],1),(_vm.cotizadorHotels.ocupation.length > 1)?_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-tooltip',{attrs:{"top":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteOcupationRoomHotelCotizador(_vm.pos)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDeleteOutline))])],1)]}}],null,false,4047419405)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.delete')))])])],1):_vm._e()],1),_c('v-row',{staticClass:"mt-0 pt-0",attrs:{"align":"center","justify":"center"}},[_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"6","lg":"6","md":"6"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('booking.adultos')))])]),_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"6","lg":"6","md":"6"}},[_c('v-btn',{staticClass:"mr-2 ml-2",attrs:{"disabled":_vm.min_adult == _vm.room.adults,"outlined":"","x-small":"","fab":"","color":"#007BC3"},on:{"click":_vm.resAdult}},[_c('v-icon',[_vm._v("mdi-minus")])],1),_c('label',[_vm._v(_vm._s(_vm.room.adults))]),_c('v-btn',{staticClass:"mr-2 ml-2",attrs:{"disabled":_vm.max_adult == _vm.room.adults ||
              _vm.max_ocupation == _vm.room.adults + _vm.room.childs ||
              _vm.max_travels == _vm.calculateCantTravels,"outlined":"","x-small":"","fab":"","color":"#007BC3"},on:{"click":_vm.sumAdult}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-col',{staticClass:"mt-0 pt-0",attrs:{"cols":"6","lg":"6","md":"6"}},[_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.$t('lbl.young')))]),_c('br'),_c('small',{staticClass:"gothBtMine tituloOcupacion"},[_vm._v(" 0-"+_vm._s(_vm.maxAge)+" "+_vm._s(_vm.$t('lbl.years')))])]),_c('v-col',{staticClass:"mt-0 pt-0",attrs:{"cols":"6","lg":"6","md":"6"}},[_c('v-btn',{staticClass:"mr-2 ml-2",attrs:{"disabled":_vm.min_child == _vm.room.childs,"outlined":"","x-small":"","fab":"","color":"#007BC3"},on:{"click":_vm.resChild}},[_c('v-icon',[_vm._v("mdi-minus")])],1),_c('label',[_vm._v(_vm._s(_vm.room.childs))]),_c('v-btn',{staticClass:"mr-2 ml-2",attrs:{"disabled":_vm.max_child == _vm.room.childs ||
              _vm.max_ocupation == _vm.room.adults + _vm.room.childs ||
              _vm.max_travels == _vm.calculateCantTravels,"outlined":"","x-small":"","fab":"","color":"#007BC3"},on:{"click":_vm.sumChild}},[_c('v-icon',[_vm._v("mdi-plus")])],1)],1),_c('v-col',{staticClass:"mt-0 pt-0",attrs:{"cols":"12","lg":"12","md":"12"}},[_c('v-row',[(_vm.room.childs > 0)?_c('v-col',{staticClass:"mb-0 pb-0",attrs:{"cols":"12","lg":"12","md":"12"}},[_c('span',[_vm._v(_vm._s(_vm.$t('lbl.edadChild')))])]):_vm._e(),_vm._l((_vm.room.edades),function(edad,j){return _c('v-col',{key:j,staticClass:"pr-2 pt-0 mt-0",attrs:{"cols":"12","lg":"4","md":"4"}},[_c('EdadOcupation',{attrs:{"edad":edad,"edades":_vm.edades,"i-hab":_vm.pos,"i-edad":j}})],1)})],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }