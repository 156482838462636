var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{staticClass:"pr-0",attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"d-flex justify-start align-center"},[(_vm.destinosCotizadorFlight[_vm.index].origen === null || _vm.destinosCotizadorFlight[_vm.index].origen === '')?_c('v-autocomplete',{staticStyle:{"border-radius":"5px 0px 0px 5px"},attrs:{"items":_vm.itemsOrigen,"search-input":_vm.searchOrigen,"label":_vm.$t('lbl.from'),"outlined":"","dense":"","item-text":"name","item-value":"id","single-line":"","clearable":"","return-object":""},on:{"update:searchInput":function($event){_vm.searchOrigen=$event},"update:search-input":function($event){_vm.searchOrigen=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.noResultOrigen ? _vm.$t('booking.noResultSearchFlight') : _vm.$t('booking.origenSearch'))+" ")])],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiAirplaneTakeoff)+" ")]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}}],null,false,1985503479),model:{value:(_vm.destinosCotizadorFlight[_vm.index].origen),callback:function ($$v) {_vm.$set(_vm.destinosCotizadorFlight[_vm.index], "origen", $$v)},expression:"destinosCotizadorFlight[index].origen"}}):_c('v-text-field',{ref:"autocomplete",staticStyle:{"border-radius":"5px 0px 0px 5px"},attrs:{"label":_vm.$t('lbl.from'),"outlined":"","dense":"","single-line":"","clearable":""},on:{"click:clear":_vm.clearOrigen,"input":_vm.inputOrigen},model:{value:(_vm.destinosCotizadorFlight[_vm.index].origen.name),callback:function ($$v) {_vm.$set(_vm.destinosCotizadorFlight[_vm.index].origen, "name", $$v)},expression:"destinosCotizadorFlight[index].origen.name"}}),_c('v-btn',{attrs:{"color":"primary","text":"","fab":"","x-small":"","disabled":!_vm.destinosCotizadorFlight[_vm.index].origen || !_vm.destinosCotizadorFlight[_vm.index].destino},on:{"click":_vm.invertDestinos}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiSwapHorizontal))])],1)],1)]),_c('v-col',{staticClass:"pl-0",attrs:{"cols":"12","md":!_vm.cotizadorFlight.destinosMultiples ? '4' : _vm.destinosCotizadorFlight.length <= 2 ? '4' : '3'}},[(_vm.destinosCotizadorFlight[_vm.index].destino === null || _vm.destinosCotizadorFlight[_vm.index].destino === '')?_c('v-autocomplete',{staticStyle:{"border-radius":"0px 5px 5px 0px"},attrs:{"items":_vm.itemsDestino,"search-input":_vm.searchDestino,"label":_vm.$t('lbl.to'),"outlined":"","dense":"","item-text":"name","item-value":"id","return-object":"","single-line":"","clearable":""},on:{"update:searchInput":function($event){_vm.searchDestino=$event},"update:search-input":function($event){_vm.searchDestino=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.noResultDestino ? _vm.$t('booking.noResultSearchFlight') : _vm.$t('booking.destinoSearch'))+" ")])],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.icons.mdiAirplaneTakeoff)+" ")]),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(((item.name.slice(0, 20)) + "...")))])]}}],null,false,3702106497),model:{value:(_vm.destinosCotizadorFlight[_vm.index].destino),callback:function ($$v) {_vm.$set(_vm.destinosCotizadorFlight[_vm.index], "destino", $$v)},expression:"destinosCotizadorFlight[index].destino"}}):_c('v-text-field',{staticStyle:{"border-radius":"0px 5px 5px 0px"},attrs:{"label":_vm.$t('lbl.to'),"outlined":"","dense":"","single-line":"","clearable":""},on:{"click:clear":_vm.clearDestino,"input":_vm.inputDestino},model:{value:(_vm.destinosCotizadorFlight[_vm.index].destino.name),callback:function ($$v) {_vm.$set(_vm.destinosCotizadorFlight[_vm.index].destino, "name", $$v)},expression:"destinosCotizadorFlight[index].destino.name"}})],1),_c('v-col',{class:!_vm.cotizadorFlight.onlyIda && !_vm.cotizadorFlight.destinosMultiples ? 'pr-0' : '',attrs:{"cols":"12","md":!_vm.cotizadorFlight.onlyIda && !_vm.cotizadorFlight.destinosMultiples ? '2' : '4'}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({style:(!_vm.cotizadorFlight.onlyIda && !_vm.cotizadorFlight.destinosMultiples ? 'border-radius: 5px 0px 0px 5px' : ''),attrs:{"label":_vm.$t('booking.ida'),"persistent-hint":"","readonly":"","outlined":"","dense":""},model:{value:(_vm.computedFromDateFormatted),callback:function ($$v) {_vm.computedFromDateFormatted=$$v},expression:"computedFromDateFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFrom),callback:function ($$v) {_vm.menuFrom=$$v},expression:"menuFrom"}},[_c('v-date-picker',{attrs:{"no-title":"","min":new Date(Date.now()).toISOString().substr(0, 10),"locale":"es","show-current":false},on:{"input":function($event){_vm.menuFrom = false
          _vm.activeToDate()}},model:{value:(_vm.destinosCotizadorFlight[_vm.index].from),callback:function ($$v) {_vm.$set(_vm.destinosCotizadorFlight[_vm.index], "from", $$v)},expression:"destinosCotizadorFlight[index].from"}})],1)],1),(!_vm.cotizadorFlight.onlyIda && !_vm.cotizadorFlight.destinosMultiples)?_c('v-col',{staticClass:"pl-0",attrs:{"cols":"12","md":"2"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"border-radius":"0px 5px 5px 0px"},attrs:{"disabled":!_vm.destinosCotizadorFlight[_vm.index].from,"label":_vm.$t('booking.back'),"persistent-hint":"","readonly":"","outlined":"","dense":""},model:{value:(_vm.computedToDateFormatted),callback:function ($$v) {_vm.computedToDateFormatted=$$v},expression:"computedToDateFormatted"}},'v-text-field',attrs,false),on))]}}],null,false,670353377),model:{value:(_vm.menuTo),callback:function ($$v) {_vm.menuTo=$$v},expression:"menuTo"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":"es","show-current":false,"min":_vm.destinosCotizadorFlight[_vm.index].from
            ? _vm.$moment(_vm.destinosCotizadorFlight[_vm.index].from)
              .add(1, 'day')
              .toDate()
              .toISOString()
              .substr(0, 10)
            : new Date(Date.now()).toISOString().substr(0, 10)},on:{"input":function($event){_vm.menuTo = false}},model:{value:(_vm.destinosCotizadorFlight[_vm.index].to),callback:function ($$v) {_vm.$set(_vm.destinosCotizadorFlight[_vm.index], "to", $$v)},expression:"destinosCotizadorFlight[index].to"}})],1)],1):_vm._e(),(_vm.cotizadorFlight.destinosMultiples && _vm.destinosCotizadorFlight.length > 2)?_c('v-col',{attrs:{"cols":"12","md":"1"}},[_c('v-btn',{attrs:{"color":"error","text":"","fab":"","small":""},on:{"click":function($event){return _vm.deleteDestinoCotizadorFligth(_vm.index)}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDelete))])],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }