<template>
  <v-row>
    <v-col
      cols="12"
      md="4"
      class="pr-0"
    >
      <div class="d-flex justify-start align-center">
        <v-autocomplete
          v-if="destinosCotizadorFlight[index].origen === null || destinosCotizadorFlight[index].origen === ''"
          v-model="destinosCotizadorFlight[index].origen"
          :items="itemsOrigen"
          :search-input.sync="searchOrigen"
          :label="$t('lbl.from')"
          outlined
          dense
          style="border-radius: 5px 0px 0px 5px"
          item-text="name"
          item-value="id"
          single-line
          clearable
          return-object
        >
          <template v-slot:no-data>
            <v-list-item>
              <v-list-item-title>
                {{ noResultOrigen ? $t('booking.noResultSearchFlight') : $t('booking.origenSearch') }}
              </v-list-item-title>
            </v-list-item>
          </template>
          <template v-slot:item="{ item }">
            <v-icon left>
              {{ icons.mdiAirplaneTakeoff }}
            </v-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item-content>
          </template>
          <template v-slot:selection="{ item }">
            {{ item.name }}
          </template>
        </v-autocomplete>
        <v-text-field
          v-else
          ref="autocomplete"
          v-model="destinosCotizadorFlight[index].origen.name"
          :label="$t('lbl.from')"
          outlined
          dense
          style="border-radius: 5px 0px 0px 5px"
          single-line
          clearable
          @click:clear="clearOrigen"
          @input="inputOrigen"
        >
        </v-text-field>
        <v-btn
          color="primary"
          text
          fab
          x-small
          :disabled="!destinosCotizadorFlight[index].origen || !destinosCotizadorFlight[index].destino"
          @click="invertDestinos"
        >
          <v-icon>{{ icons.mdiSwapHorizontal }}</v-icon>
        </v-btn>
      </div>
    </v-col>
    <v-col
      cols="12"
      :md="!cotizadorFlight.destinosMultiples ? '4' : destinosCotizadorFlight.length <= 2 ? '4' : '3'"
      class="pl-0"
    >
      <v-autocomplete
        v-if="destinosCotizadorFlight[index].destino === null || destinosCotizadorFlight[index].destino === ''"
        v-model="destinosCotizadorFlight[index].destino"
        :items="itemsDestino"
        :search-input.sync="searchDestino"
        :label="$t('lbl.to')"
        outlined
        dense
        style="border-radius: 0px 5px 5px 0px"
        item-text="name"
        item-value="id"
        return-object
        single-line
        clearable
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              {{ noResultDestino ? $t('booking.noResultSearchFlight') : $t('booking.destinoSearch') }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <template v-slot:item="{ item }">
          <v-icon left>
            {{ icons.mdiAirplaneTakeoff }}
          </v-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </template>
        <template v-slot:selection="{ item }">
          <span>{{ `${item.name.slice(0, 20)}...` }}</span>
        </template>
      </v-autocomplete>
      <v-text-field
        v-else
        v-model="destinosCotizadorFlight[index].destino.name"
        :label="$t('lbl.to')"
        outlined
        dense
        style="border-radius: 0px 5px 5px 0px"
        single-line
        clearable
        @click:clear="clearDestino"
        @input="inputDestino"
      >
      </v-text-field>
      <!--<v-autocomplete
        v-model="destinosCotizadorFlight[index].destino"
        :items="itemsDestino"
        :search-input.sync="searchDestino"
        hide-details
        :label="$t('lbl.to')"
        outlined
        dense
        style="border-radius: 0px 5px 5px 0px"
        return-object
      >
        <template v-slot:no-data>
          <v-list-item>
            <v-list-item-title>
              {{ $t('booking.destinoSearch') }}
            </v-list-item-title>
          </v-list-item>
        </template>
        <template v-slot:selection="{ item }">
          <span>{{ `${item.name.slice(0, 20)}...` }}</span>
        </template>
        <template v-slot:item="{ item }">
          <v-icon left>
            {{ icons.mdiAirplaneTakeoff }}
          </v-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </template>
      </v-autocomplete>-->
    </v-col>
    <v-col
      cols="12"
      :md="!cotizadorFlight.onlyIda && !cotizadorFlight.destinosMultiples ? '2' : '4'"
      :class="!cotizadorFlight.onlyIda && !cotizadorFlight.destinosMultiples ? 'pr-0' : ''"
    >
      <v-menu
        v-model="menuFrom"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="computedFromDateFormatted"
            :label="$t('booking.ida')"
            persistent-hint
            readonly
            v-bind="attrs"
            outlined
            dense
            :style="
              !cotizadorFlight.onlyIda && !cotizadorFlight.destinosMultiples ? 'border-radius: 5px 0px 0px 5px' : ''
            "
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="destinosCotizadorFlight[index].from"
          no-title
          :min="new Date(Date.now()).toISOString().substr(0, 10)"
          locale="es"
          :show-current="false"
          @input="
            menuFrom = false
            activeToDate()
          "
        ></v-date-picker>
      </v-menu>
      <!--<vc-date-picker
        v-model="destinosCotizadorFlight[index].from"
        outlined
        dense
        hide-details
        :min-date="new Date()"
        mode="date"
        :model-config="modelConfig"
        class="d-flex justify-center;z-index: 1 !important"
        @input="activeToDate"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <v-text-field
            :label="$t('booking.ida')"
            outlined
            dense
            :value="inputValue"
            :style="
              !cotizadorFlight.onlyIda && !cotizadorFlight.destinosMultiples ? 'border-radius: 5px 0px 0px 5px' : ''
            "
            v-on="inputEvents"
          >
          </v-text-field>
        </template>
      </vc-date-picker>-->
    </v-col>
    <v-col
      v-if="!cotizadorFlight.onlyIda && !cotizadorFlight.destinosMultiples"
      cols="12"
      md="2"
      class="pl-0"
    >
      <v-menu
        v-model="menuTo"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="computedToDateFormatted"
            :disabled="!destinosCotizadorFlight[index].from"
            :label="$t('booking.back')"
            persistent-hint
            readonly
            v-bind="attrs"
            outlined
            dense
            style="border-radius: 0px 5px 5px 0px"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="destinosCotizadorFlight[index].to"
          no-title
          locale="es"
          :show-current="false"
          :min="
            destinosCotizadorFlight[index].from
              ? $moment(destinosCotizadorFlight[index].from)
                .add(1, 'day')
                .toDate()
                .toISOString()
                .substr(0, 10)
              : new Date(Date.now()).toISOString().substr(0, 10)
          "
          @input="menuTo = false"
        ></v-date-picker>
        <!--<vc-date-picker
        v-model="destinosCotizadorFlight[index].to"
        outlined
        dense
        hide-details
        :min-date="
          $moment(destinosCotizadorFlight[index].from)
            .add(1, 'days')
            .toDate()
        "
        mode="date"
        :model-config="modelConfig"
      >
        <template v-slot="{ inputValue, inputEvents }">
          <v-text-field
            ref="dateTo"
            :disabled="!destinosCotizadorFlight[index].from"
            :label="$t('booking.back')"
            outlined
            dense
            :value="inputValue"
            style="border-radius: 0px 5px 5px 0px"
            v-on="inputEvents"
          >
          </v-text-field>
        </template>
      </vc-date-picker>-->
      </v-menu>
    </v-col>
    <v-col
      v-if="cotizadorFlight.destinosMultiples && destinosCotizadorFlight.length > 2"
      cols="12"
      md="1"
    >
      <v-btn
        color="error"
        text
        fab
        small
        @click="deleteDestinoCotizadorFligth(index)"
      >
        <v-icon>{{ icons.mdiDelete }}</v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

import {
  mdiCar,
  mdiBed,
  mdiSeatPassenger,
  mdiWalletTravel,
  mdiAirplaneTakeoff,
  mdiAirplane,
  mdiAirplaneLanding,
  mdiSwapHorizontal,
  mdiOfficeBuilding,
  mdiEye,
  mdiPencil,
  mdiDelete,
  mdiPlusThick,
  mdiChevronRight,
  mdiPlus,
  mdiMinus,
} from '@mdi/js'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    index: {
      type: Number,
    },
    // eslint-disable-next-line vue/require-default-prop
    destino: {
      type: Object,
    },
  },
  data() {
    return {
      itemsOrigen: [],
      noResultOrigen: false,
      searchOrigen: null,
      itemsDestino: [],
      noResultDestino: false,
      searchDestino: null,
      loading: false,
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      icons: {
        mdiCar,
        mdiBed,
        mdiSeatPassenger,
        mdiWalletTravel,
        mdiAirplaneTakeoff,
        mdiAirplane,
        mdiAirplaneLanding,
        mdiSwapHorizontal,
        mdiOfficeBuilding,
        mdiEye,
        mdiPencil,
        mdiDelete,
        mdiPlusThick,
        mdiChevronRight,
        mdiPlus,
        mdiMinus,
      },
      dateFrom: '',
      menuFrom: false,
      menuTo: false,
    }
  },
  computed: {
    ...mapState({
      cotizadorFlight: state => state.app.cotizadorFlight,
      destinosCotizadorFlight: state => state.app.destinosCotizadorFlight,
      computedFromDateFormatted() {
        return this.destinosCotizadorFlight[this.index].from
          ? this.$moment(this.destinosCotizadorFlight[this.index].from).format('D-MMM-YY')
          : ''
      },
      computedToDateFormatted() {
        return this.destinosCotizadorFlight[this.index].to
          ? this.$moment(this.destinosCotizadorFlight[this.index].to).format('D-MMM-YY')
          : ''
      },
    }),
  },
  watch: {
    searchOrigen(val) {
      if (val.length > 2) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterOrigen(val.toLowerCase())
      } else {
        this.noResultOrigen = false
      }
    },
    searchDestino(val) {
      if (val.length > 2) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterDestino(val.toLowerCase())
      } else {
        this.noResultDestino = false
      }
    },
    dateFrom() {
      this.destinosCotizadorFlight[this.index].from = this.dateFrom
      if (!this.cotizadorFlight.onlyIda && !this.cotizadorFlight.destinosMultiples) {
        setTimeout(() => {
          this.menuTo = true
        }, 100)
      }
    },
  },
  methods: {
    ...mapMutations(['deleteDestinoCotizadorFligth']),
    filterOrigen(v) {
      this.itemsOrigen = []
      // eslint-disable-next-line no-empty
      if (v !== '') {
        this.axios
          .post(
            'airports-names-filter-origen-contrate',
            { search: v },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            const result = res.data.data.data

            result.forEach(element => {
              // eslint-disable-next-line no-nested-ternary
              this.itemsOrigen.push({
                id: element.id,
                name: element.nameComodin,
                iataComodin: element.iataComodin,
                nombre: element.name,
                iata: element.iata,

                // city: element.city,
                // state: element.state,
                country: element.country,
              })
            })

            if (this.itemsOrigen.length === 0) {
              this.noResultOrigen = true
            }
          })
      }
    },
    filterDestino(v) {
      this.itemsDestino = []
      if (v === '') {
        this.itemsDestino = []
      } else {
        this.axios
          .post(
            'airports-names-filter-destino-contrate',
            { search: v },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            const result = res.data.data.data

            result.forEach(element => {
              // eslint-disable-next-line no-nested-ternary
              this.itemsDestino.push({
                id: element.id,
                name: element.nameComodin,
                iataComodin: element.iataComodin,
                nombre: element.name,
                iata: element.iata,

                // city: element.city,
                // state: element.state,
                country: element.country,
              })
            })

            if (this.itemsDestino.length === 0) {
              this.noResultDestino = true
            }
          })
      }
    },
    activeToDate() {
      if (!this.cotizadorFlight.onlyIda && !this.cotizadorFlight.destinosMultiples) {
        setTimeout(() => {
          this.menuTo = true
        }, 100)
      }
    },
    invertDestinos() {
      const { origen } = this.destinosCotizadorFlight[this.index]
      const { destino } = this.destinosCotizadorFlight[this.index]
      this.itemsOrigen = []
      this.itemsDestinos = []

      this.itemsOrigen.push(destino)
      this.itemsDestino.push(origen)
      this.destinosCotizadorFlight[this.index].origen = destino
      this.destinosCotizadorFlight[this.index].destino = origen
    },
    clearOrigen() {
      this.destinosCotizadorFlight[this.index].origen = null
    },
    inputOrigen() {
      if (
        this.destinosCotizadorFlight[this.index].origen.name === null
        || this.destinosCotizadorFlight[this.index].origen.name === ''
      ) {
        this.clearOrigen()
      }
    },
    clearDestino() {
      this.destinosCotizadorFlight[this.index].destino = null
    },
    inputDestino() {
      if (
        this.destinosCotizadorFlight[this.index].destino.name === null
        || this.destinosCotizadorFlight[this.index].destino.name === ''
      ) {
        this.clearDestino()
      }
    },
  },
}
</script>

<style>
.theme--light.v-text-field.v-input--is-disabled .v-input__slot:before {
  border: none;
}

.v-input__slot {
  margin-bottom: 0px !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div
  > div
  > div {
  padding: 0 !important;
}

#app
  > div.v-application--wrap
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(3)
  > div {
  padding: 0 !important;
  margin-top: 0 !important;
}

#app
  > div.v-application--wrap
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div
  > div
  > div
  > div
  > div:nth-child(4)
  > div {
  padding: 0 !important;
  margin-top: 0 !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div::before {
  box-shadow: none !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div {
  box-shadow: none !important;
  padding-bottom: 10px;
}
#app
  > div.v-application--wrap
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-card--flat.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(2)
  > div.pl-0.col-md-3.col-12
  > div
  > div
  > div
  > div
  > label {
  padding-left: 10px !important;
  z-index: 999 !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div {
  box-shadow: none !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div {
  box-shadow: none !important;
}

#app
  > div
  > main
  > div
  > div
  > div
  > div
  > div
  > div.v-card.v-sheet.theme--light
  > div
  > div
  > div
  > div.v-window-item.v-window-item--active
  > form
  > div
  > div:nth-child(4)
  > div
  > div
  > div::before {
  box-shadow: none !important;
}
.boton-lefttMine,
.boton-rightMine {
  max-height: 20px !important;
  min-width: 40px !important;
  padding: 0px !important;
  border: none !important;
}

#weirdButMine {
  border: 1px solid #dcdade;
  max-height: 40px;
  border-radius: 5px;
}

#weirdButMine input {
  height: 20px;
  text-align: center;
}

#weirdButMine div:nth-child(2) > div {
  height: 20px;
  padding: 0;
  margin: 0;
}

#weirdButMine .v-input__slot:after {
  border: none !important;
}

#weirdButMine .v-input__slot:before {
  border: none;
}

#weirdButMine button::before {
  background-color: rgba(255, 255, 255, 0);
}

#weirdButMine button .v-ripple__container {
  color: rgba(255, 255, 255, 0);
  background-color: rgba(255, 255, 255, 0);
}

/* PARA LA FECHA DE VCALENDAR*/
.vc-popover-content-wrapper {
  margin: 0px;
  position: fixed !important;
  inset: 0px auto auto 0px;
  transform: translate(150px, 80px);
}
</style>
